import React, { useEffect, useState } from "react";
import "./LoginPage.css";
import toast from "react-hot-toast";
import {
  getApiCallDetailsPath,
  getApiCallHostPath,
  getZinoDomainAppData,
  toastErrorMessageStyle
} from "../../utils/apiCallFunction";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import resendPasswordImg from "../../Images/resend_password.svg";
import { encryptData } from "../../utils/encryptData";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");
  const [errorState, setErrorState] = useState({ newPassword: false, reenterPassword: false });
  const [ResetPasswordDone, setResetPasswordDone] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const code = urlParams.get("code");
  const navigate = useNavigate();

  const handlePassword = (key) => (e) => {
    const value = e.target.value;
    if (key === "new password") setNewPassword(value);
    else if (key === "re-enter password") setReenterPassword(value);
  };

  function handleErrors() {
    let tempObj = JSON.parse(JSON.stringify(errorState));
    tempObj = {
      newPassword: newPassword.length < 4,
      reenterPassword: reenterPassword !== newPassword
    };
    setErrorState({
      newPassword: newPassword.length < 4,
      reenterPassword: reenterPassword !== newPassword
    });
    return tempObj;
  }

  async function handleResetPassword() {
    const tempObj = handleErrors();
    if (tempObj?.newPassword === false && tempObj?.reenterPassword === false) {
      try {
        await axios.put(`${getApiCallHostPath()}/api/v1/verifyPassword`, {
          code: code,
          email: encryptData(email),
          new_password: encryptData(newPassword)
        });
        setResetPasswordDone(true);
      } catch (error) {
        toast.error(error?.response?.message || "Reset link has been expired", toastErrorMessageStyle());
      }
    }
  }

  const handleBacktoHome = () => {
    navigate("/login");
  };

  useEffect(() => {
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || Reset password`;
  }, []);

  return (
    <div className="login_page_outer_wrap">
      <div className="login_page_img_outer">
        <img
          className="login_page_img"
          src={
            getZinoDomainAppData()?.login_image
              ? `${getApiCallDetailsPath()}/api/v1/public/${getZinoDomainAppData()?.login_image}`
              : resendPasswordImg
          }
          alt="login img"
        ></img>
      </div>
      <div className="login_outer_container">
        {ResetPasswordDone ? (
          <div className="login_inner_container">
            <label className="zino_label zino_success_label">Reset Password done Successfully!</label>
            <div className="login_button_container">
              <button className="zino_btn_primary login" onClick={handleBacktoHome}>
                Back to Login
              </button>
            </div>
          </div>
        ) : (
          <div className="login_inner_container">
            <h1 style={{ fontSize: "24px" }}>Reset Your Password</h1>
            <div className="login_field">
              <label className="zino_label">New Password</label>
              <input
                value={newPassword}
                onChange={handlePassword("new password")}
                className="zino_inputBox"
                placeholder="Enter new password"
              />
              {errorState?.newPassword === true && (
                <p className="errorMessage">Password must contains atleast 4 characters</p>
              )}
              <label className="zino_label">Re-enter new Password</label>
              <input
                value={reenterPassword}
                onChange={handlePassword("re-enter password")}
                className="zino_inputBox"
                placeholder="Confirm new password"
              />
              {errorState?.reenterPassword === true && <p className="errorMessage">Passwords mismatch!</p>}
              <button className="zino_btn_primary login" onClick={handleResetPassword}>
                Reset Password
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
