import forge from "node-forge";

const publicKeyPem = process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY?.replace(/\\n/g, "\n");

export const encryptData = (data) => {
  try {
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const encrypted = publicKey.encrypt(forge.util.encodeUtf8(data), "RSAES-PKCS1-V1_5");
    return forge.util.encode64(encrypted);
  } catch (error) {
    console.log({ error });
  }
};
