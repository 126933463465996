import { useEffect } from "react";
import moment from "moment";

let intervalId;

const useTokenRefresher = (refreshTime, expTime, refreshToken) => {
  useEffect(() => {
    if (!refreshTime || !expTime || !refreshToken) return;

    const handleTokenRefresh = () => {
      const currentTime = moment().unix();

      if (currentTime >= refreshTime && currentTime < expTime) {
        refreshToken(); // Call API to refresh token
      }
    };

    intervalId = setInterval(handleTokenRefresh, 600000); //every ten minutes check the token

    handleTokenRefresh();

    return () => {
      clearInterval(intervalId);
    };
  }, [refreshTime, expTime, refreshToken]);
};

export default useTokenRefresher;
