import React, { Suspense, useEffect, useState, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./LoginPage.css";

import { setIsMobileUserAgent } from "../../redux/userview/userviewaction";
import { getStorageItem, removeStorageItem, setStorageItem } from "../../utils/storageFunction";
import { getApiCallDetailsPath, getZinoDomainAppData } from "../../utils/apiCallFunction";
import loginPageImg from "../../Images/login_img.svg";

const isDefaultLogin = process.env.REACT_APP_DEFAULT_LOGIN === "true";
const isCognitoLogin = process.env.REACT_APP_COGNITO === "true";

const DefaultLoginForm = isDefaultLogin ? lazy(() => import("./LoginForm")) : null;
const CognitoComponent = isCognitoLogin ? lazy(() => import("./ExternalService/Cognito")) : null;
// const GuestUser = isDefaultLogin ? lazy(() => import("./GuestUser")) : null;

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticate, logincall, user, isMobileUserAgent } = useSelector((state) => state.userviewstore);
  const [MicrosoftAuth, setMicrosoftAuth] = useState(null);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  // const [showMore, setShowMore] = useState(false);

  const Zino_domain_app_data = JSON.parse(localStorage.getItem("Zino_domain_app_data"));

  let environmentValue = process.env.REACT_APP_ENVIRONMENT || "local";

  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    setDeferredPrompt(event);
    setShowInstallPrompt(true);
  };

  //install popup
  const installApp = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        setShowInstallPrompt(false);
      });
    }
  };

  const removePopup = () => {
    setShowInstallPrompt(false);
  };

  //load ms component based on flag TODO:need to rework, less secured
  useEffect(() => {
    if (Zino_domain_app_data?.sso_microsoft) {
      import("./ExternalService/Microsoft").then((module) => {
        setMicrosoftAuth(() => module.default);
      });
    }
  }, [Zino_domain_app_data?.sso_microsoft]);

  useEffect(() => {
    if (authenticate && !logincall && getStorageItem("Zino_app_user")) {
      let environmentValue = process.env.REACT_APP_ENVIRONMENT || "local";
      let userGoto = user?.goto_page;
      if (userGoto && environmentValue !== "local") {
        userGoto = userGoto.split("/");
        let app_id = getStorageItem("Zino_app_id");
        if (app_id) {
          userGoto = `/${app_id}/${userGoto[userGoto.length - 1]}`;
        } else {
          userGoto = `/${userGoto[userGoto.length - 1]}`;
        }
        navigate(userGoto);
      }

      navigate("/applist");
    }

    document.title = `${getZinoDomainAppData()?.title || "Zino"} || Login`;

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate, logincall]);

  useEffect(() => {
    removeStorageItem("", [
      "Zino_public_app_id",
      "Zino_public_domain_web",
      "Zino_public_app_name",
      "Zino_app_id",
      "Zino_domain_web",
      "Zino_app_name",
      "Zino_app_theme_id",
      "Zino_app_user_go_to",
      "Zino_app_module_header",
      "selectedApp"
    ]);
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {environmentValue === "local" && (
        <div className="login_page_appbar_dev_hide">
          <label className="switch-mobile-desktop">
            <input
              className="switch-mobile-desktop-input"
              type="checkbox"
              checked={isMobileUserAgent}
              onChange={(e) => {
                dispatch(setIsMobileUserAgent(e.target.checked));
                setStorageItem("Zino_Is_Mobile_User_Agent", e.target.checked);
              }}
            />
            <span className="switch-mobile-desktop-slider"></span>
            <span className="material-symbols-outlined switch-desktop-slider">laptop_windows</span>
            <span className="material-symbols-outlined switch-mobile-slider">phone_android</span>
          </label>
        </div>
      )}
      {showInstallPrompt && isMobileUserAgent && (
        <div className="install-prompt">
          <p>Install app to your device for a better experience!</p>
          <div className="install-prompt-button-container">
            <button onClick={removePopup} className="zino_btn_outline_primary popup_button">
              Cancel
            </button>
            <button onClick={installApp} className="zino_btn_primary popup_button">
              Install
            </button>
          </div>
        </div>
      )}

      <div className={isMobileUserAgent ? "mobile_login_page_outer_wrap" : "login_page_outer_wrap"}>
        <div className="login_page_img_outer">
          <img
            className="login_page_img"
            src={
              getZinoDomainAppData()?.login_image
                ? `${getApiCallDetailsPath()}/api/v1/public/${getZinoDomainAppData()?.login_image}`
                : loginPageImg
            }
            alt="login img"
          ></img>
        </div>
        <div className="login_outer_container">
          <div className="login_inner_container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h1 style={{ fontSize: "24px" }}>Login</h1>
              {/* {isDefaultLogin && (
                <Suspense fallback={<p></p>}>
                  <GuestUser />
                </Suspense>
              )} */}
            </div>
            <div className="login_field">
              {isDefaultLogin && (
                <Suspense fallback={<p>Loading...</p>}>
                  <DefaultLoginForm />
                </Suspense>
              )}

              {Zino_domain_app_data?.sso_microsoft && <MicrosoftAuth />}

              {isCognitoLogin && (
                <Suspense fallback={<p>Loading...</p>}>
                  <CognitoComponent />
                </Suspense>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LoginPage;
